import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpUtilsService} from "../../../services/utils/http-utils.service";
import {CpfMask} from "../../../shared/constants/masks";
import {environment} from "../../../../environments/environment";
import {MyHttpResponseErrorService} from "../../../services/http-response-error/my-http-response-error.service";
import {catchError} from "rxjs/operators";
import {IHttpResponseGeneric} from "../../../shared/interfaces/i-http-response-generic";
import {ICustomerSignatureInfoValidationCpfReturn, IGetMetadataReturn, IMetadata} from "../definitions";
import {ICustomerSignatureRouteParams} from "./definitions";
import {StringUtilsService} from "../../../services/utils/string-utils.service";

@Component({
  selector: 'app-customer-signature-info-validation',
  templateUrl: './customer-signature-info-validation.component.html',
  styleUrls: ['./customer-signature-info-validation.component.scss']
})
export class CustomerSignatureInfoValidationComponent implements OnInit, OnDestroy {
  public validationForm!: FormGroup;
  public isLoading: boolean = false;
  public cpfMask = CpfMask;
  public geoStart: number = 0;
  public geoEnd: number = 0;
  public isGeoBlock: boolean = false;
  public formValid = false;

  private observableArray: Array<Subscription> = [];
  private routeParams!: ICustomerSignatureRouteParams;

  constructor(
    private stringUtils: StringUtilsService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private httpUtils: HttpUtilsService,
    private myHttpRespErr: MyHttpResponseErrorService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) {
    this.observableArray.push(
      activatedRoute.paramMap.subscribe((value: ParamMap) => {
        this.routeParams = {
          guid: value.get('guid') ?? '',
          key: value.get('key') ?? '',
          token: value.get('token') ?? '',
          uniqueKey: value.get('uniqueKey') ?? ''
        }
      })
    );
  }

  public async ngOnInit() {
    this.initForm();


  }

  public ngOnDestroy(): void {
    for (let i = 0; i < this.observableArray.length; ++i) {
      this.observableArray[i].unsubscribe();
    }
  }

  public async submitForm() {
    if (this.validationForm.invalid) {
      return;
    }

    if (!this.stringUtils.isCpfValid(this.validationForm.get('Cpf')?.value)) {
      this.snackBar.open('Cpf inválido.', 'OK', {
        duration: 2500
      });

      return;
    }

    try {
      this.isLoading = true;


      const cpf = this.validationForm.get('Cpf')?.value.replace(/[.-]/g, '');


      // get location
      if (this.routeParams.guid != '') {
        // Aguarda a execução da função getLocationInfo
        await this.getLocationInfo(false, cpf);
      } else {
        await this.getLocationInfo(true, cpf);
      }

      if (this.isGeoBlock) {
        await this.getGeoDevice();
      }

      if (this.formValid) {

        let postData;

        const link = this.routeParams.uniqueKey === '' || this.routeParams.uniqueKey === null ? 'PreSign' : 'PreSignKey';


        if (link === 'PreSign') {
          postData = {
            DocumentGuid: this.routeParams.guid,
            DocumentKey: this.routeParams.key,
            //key: this.routeParams.uniqueKey,
            DocumentSigner: this.validationForm.get('Cpf')?.value.replace(/[.-]/g, '')
          };
        } else {
          postData = {
            // DocumentGuid: this.routeParams.guid,
            // DocumentKey: this.routeParams.key,
            key: this.routeParams.uniqueKey,
            DocumentSigner: this.validationForm.get('Cpf')?.value.replace(/[.-]/g, '')
          };
        }


        this.observableArray.push(
          this.http.post(`${environment.baseUrl}/Documents/${link}`, postData, {
            headers: this.httpUtils.AddBasicHeaders()
          })
            .pipe(
              catchError((err) => this.myHttpRespErr.httpError(err, () => this.isLoading = false))
            )
            .subscribe(resp => {
              this.isLoading = false;

              if (resp === null) {
                this.snackBar.open('Não existem documentos para assinar neste Cpf.', "OK");

                return;
              }

              let data: IHttpResponseGeneric<ICustomerSignatureInfoValidationCpfReturn> =
                resp as IHttpResponseGeneric<ICustomerSignatureInfoValidationCpfReturn>;

              this.router.navigate(['/sign-list', data.data.document.documentGuid, data.data.document.documentKey, cpf, this.routeParams.uniqueKey, this.routeParams.token], {
                state: {
                  key: data.data.document.documentKey,
                  mainDocInfo: data.data,
                  cpf: cpf,
                  token: this.routeParams.token,
                  uniqueKey: this.routeParams.uniqueKey
                }
              });
            })
        );

      }


    } catch (e) {
      this.isLoading = false;

      console.error(e);
    }

  }

  private initForm() {
    this.validationForm = this.formBuilder.group({
      Cpf: ['', [Validators.required]],
    });
  }

  public getCurrentLocation(): Promise<GeolocationCoordinates> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        this.snackBar.open('Buscando geolocalização...', 'OK', {
          duration: 1500
        });

        navigator.geolocation.getCurrentPosition(
          (position) => resolve(position.coords),
          (error) => reject(error)
        );
      } else {
        reject('Geolocalização não é suportada pelo navegador.');
      }
    });
  }

  calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371; // Raio da Terra em km
    const dLat = this.degreesToRadians(lat2 - lat1);
    const dLon = this.degreesToRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.degreesToRadians(lat1)) *
      Math.cos(this.degreesToRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distância em km
  }

  degreesToRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
  }

  async getLocationInfo(isKey: boolean, cpf: string) {
    this.isLoading = true;
    let routeString: string;

    if (isKey) {
      routeString = `${environment.baseUrl}/Filters/Document/Metadata/KeyUniqueness/${this.routeParams.uniqueKey}/${cpf}`
    } else {
      routeString = `${environment.baseUrl}/Filters/Document/Metadata/${this.routeParams.guid}/AgreementCnpj`;
    }

    // Transformando o Observable em Promise
    try {
      const resp = await this.http
        .get<IHttpResponseGeneric<IMetadata[]>>(routeString,
          {headers: this.httpUtils.AddBasicHeaders()}
        )
        .pipe(
          catchError((err) => {
            this.isLoading = false;
            this.myHttpRespErr.httpError(err, () => this.isLoading = false);
            return [];
          })
        )
        .toPromise(); // Converte o Observable para Promise

      this.isLoading = false;

      if (!resp) {
        this.isGeoBlock = false;
        this.formValid = true;
        return;
      }

      this.isGeoBlock = true;
      const data = resp.data;
      const foundItem = data.find(item => item.key === 'GeoLocation');

      if (!foundItem) {
        console.log("GeoLocation not allowed!");
        this.isGeoBlock = false;
        this.formValid = true;
        return;
      }

      console.log('GeoLocation find:', foundItem.value);
      const input = foundItem.value;
      const parts = input.split(','); // Divide a string na vírgula
      this.geoStart = parseFloat(parts[0]);
      this.geoEnd = parseFloat(parts[1]);

      console.log(this.geoStart, this.geoEnd); // Resultado: 2121 -45555

    } catch (error) {
      this.isLoading = false;
      this.formValid = false;
      this.snackBar.open('Erro ao obter informações de localização.', 'OK');
    }
  }

  public async getGeoDevice() {

    try {
      const currentLocation = await this.getCurrentLocation();
      // Exemplo de outra localização para comparar
      const targetLocation = {latitude: this.geoStart, longitude: this.geoEnd}; // Curitiba, por exemplo

      console.log('TargetLocation: ' + targetLocation.latitude + ',' + targetLocation.longitude);

      // Calcular a distância
      const distance = this.calculateDistance(
        currentLocation.latitude,
        currentLocation.longitude,
        targetLocation.latitude,
        targetLocation.longitude
      );

      console.log(`A distância é ${distance.toFixed(2)} km.`);

      if (distance <= 2) {
        this.formValid = true;
        console.log('As localizações são próximas (menos de 2 km).');
      } else {

        this.formValid = false;
        this.router.navigate(['/geo-location-error'],
          {
            replaceUrl: true,
            state: {
              locationLatitude: currentLocation.latitude,
              locationLongitude: currentLocation.longitude,
              distance: distance,
              targetLocation: this.geoStart + ',' + this.geoEnd

            }

          });
      }
    } catch (error) {
      this.formValid = false;
      this.snackBar.open('Não é possível prosseguir sem a geolocalização.', 'OK', {});


    }
  }


}
